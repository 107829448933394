import React, { useRef } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useConveyer } from '@egjs/react-conveyer';
import dateFormat from 'dateformat';
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import { GatsbyImage } from 'gatsby-plugin-image';
import { WorldsFairHead } from '../components/Head';

type LogsPost = {
    databaseId: number;
    slug: string;
    title: string;
    date: string;
}

type LogsProps = {
    pageContext: {
        slug: string,
        posts: LogsPost[],
    }
} & PageProps<Queries.LogsTemplateQuery>;

const Logs = ({ data: { logs }, pageContext: { slug: pageSlug, posts } }: LogsProps) => {
    const logsRef = useRef<HTMLDivElement>(null);
    useConveyer(logsRef, {
        horizontal: true,
    });

    if (!logs) {
        return <NotFoundPage />;
    }

    const {
        tghpworldsfairTitle: title,
        tghpworldsfairIntro: intro,
        tghpworldsfairTimelineItems: timelineItems
    } = logs;

    const yearGroups: {
        [year: string]: {
            year: string;
            items: Array<NonNullable<NonNullable<NonNullable<Queries.LogsTemplateQuery['logs']>['tghpworldsfairTimelineItems']>[0]>>;
        }
    } = {};

    if (timelineItems) {
        for (const item of timelineItems) {
            if (!item || !item.year) {
                continue;
            }

            if (!yearGroups[item.year]) {
                yearGroups[item.year] = { year: item.year, items: [] };
            }

            yearGroups[item.year].items.push(item);
        }
    }

    return (
        <Layout location="logs" showSignup={true} seoData={logs.seo}>
            <header>
                <h1>{title}</h1>
                <p>{intro}</p>
            </header>
            {yearGroups &&
                <div className="log-timeline" ref={logsRef}>
                    {Object.values(yearGroups).map((yearGroup, i) =>
                        yearGroup &&
                        <div className="log-timeline__year-group" key={i}>
                            {yearGroup.year && <div className="log-timeline__year-group-title">{yearGroup.year}</div>}
                            {yearGroup.items &&
                                <div className="log-timeline__year-group-items">
                                    {yearGroup.items.map((item, j) =>
                                        <div className="log-timeline__year-item" key={j}>
                                            {item.image?.nodes && item.image?.nodes[0]?.gatsbyImage &&
                                                <div className="log-timeline__year-item-image">
                                                    <GatsbyImage image={item.image?.nodes[0]?.gatsbyImage} alt={`${item.title}`} />
                                                </div>
                                            }
                                            {item.month &&
                                                <div className="log-timeline__year-item-month">
                                                    {item.month}
                                                </div>
                                            }
                                            {item.title &&
                                                <div className="log-timeline__year-item-title">
                                                    {item.title}
                                                </div>
                                            }
                                            {item.description &&
                                                <div className="log-timeline__year-item-description">
                                                    {item.description}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    )}
                </div>
            }
            <div className="log-list">
                <div className="log-list__title">Logs</div>
                {posts.map(({ databaseId, slug, title, date }) =>
                    <a href={`${pageSlug}/${slug}`} className="log-list__item" key={databaseId}>
                        <div className="log-list__item-title">
                            {title}
                        </div>
                        <div className="log-list__item-date">
                            {dateFormat(Date.parse(date), 'd mmmm yyyy')}
                        </div>
                    </a>
                )}
            </div>
        </Layout>
    );
}

export default Logs;

export const query = graphql`
    query LogsTemplate ($id: Int!) {
        logs: wpPage(databaseId: { eq: $id }) {
            tghpworldsfairTitle
            tghpworldsfairIntro
            tghpworldsfairTimelineItems {
                year
                title
                month
                description
                image {
                    nodes {
                        gatsbyImage(layout: FULL_WIDTH, width: 335, sizes: "335px", aspectRatio: 1)
                    }
                }
            }
            ...SeoData
        }
    }
`

export const Head = () => <WorldsFairHead />